import * as React from "react";

import { Footer, Page } from "../components";
import * as css from "../styles/pages/regulations.module.scss";

const TermsAndConditions = () => {
  return (
    <Page metaTitle="Regulaminem korzystania z aplikacji Beautiful Places">
      <div className={css.wrapper}>
        <h1>Regulaminem korzystania z aplikacji</h1>
        <p>
          Pobierając aplikację lub korzystając z niej, te warunki będą
          automatycznie obowiązywać użytkownika - dlatego przed rozpoczęciem
          korzystania z aplikacji należy się z nimi dokładnie zapoznać. Zabrania
          się kopiowania lub modyfikowania aplikacji, jakiejkolwiek jej części
          lub naszych znaków towarowych w jakikolwiek sposób. Zabronione są
          próby wyodrębnienia kodu źródłowego aplikacji, a także tłumaczenia
          aplikacji na inne języki oraz tworzenia wersji pochodnych. Sama
          aplikacja oraz wszystkie znaki towarowe, prawa autorskie, prawa do baz
          danych i inne prawa własności intelektualnej z nią związane nadal
          należą do firmy <span>SmartWeb</span>.
        </p>
        <p>
          Firma <span>SmartWeb</span> dokłada wszelkich starań, aby aplikacja
          była jak najbardziej użyteczna i wydajna. Z tego powodu zastrzegamy
          sobie prawo do wprowadzania zmian w aplikacji lub pobierania opłat za
          jej usługi, w dowolnym czasie i z dowolnego powodu. Nigdy nie będziemy
          pobierać opłat za aplikację lub jej usługi, jeśli nie wyjaśnimy Ci
          dokładnie, za co płacisz.
        </p>
        <p>
          Aplikacja <span>Beautiful Places</span> przechowuje i przetwarza dane
          osobowe, które nam przekazałeś, aby świadczyć nasze Usługi. Twoim
          obowiązkiem jest dbanie o bezpieczeństwo Twojego telefonu i dostępu do
          aplikacji. Dlatego zalecamy, abyś nie stosował „jailbreaka” ani
          „rootowania” telefonu, czyli procesu usuwania ograniczeń i restrykcji
          oprogramowania nałożonych przez oficjalny system operacyjny Twojego
          urządzenia. Może to spowodować, że Twój telefon będzie podatny na
          działanie złośliwego oprogramowania, wirusów lub złośliwych programów,
          może naruszyć funkcje bezpieczeństwa telefonu i może oznaczać, że
          aplikacja <span>Beautiful Places</span> nie będzie działać poprawnie
          lub w ogóle.
        </p>
        <p>
          Aplikacja korzysta z usług stron trzecich, które deklarują swój
          Regulamin.
        </p>
        <p>
          Link do regulaminów usługodawców zewnętrznych, z których korzysta
          aplikacja
        </p>
        <ul>
          <li>
            <a href="https://policies.google.com/terms" rel="nofollow">
              Google Play Services
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/analytics"
              rel="nofollow"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li>
            <a
              href="https://firebase.google.com/terms/crashlytics"
              rel="nofollow"
            >
              Firebase Crashlytics
            </a>
          </li>
          <li>
            <a href="https://sentry.io/terms/" rel="nofollow">
              Sentry
            </a>
          </li>
        </ul>
        <p>
          Należy pamiętać, że są pewne rzeczy, za które <span>SmartWeb</span>{" "}
          nie ponosi odpowiedzialności. Niektóre funkcje aplikacji wymagają
          aktywnego połączenia z Internetem. Może to być połączenie Wi-Fi lub
          połączenie dostarczane przez operatora sieci komórkowej, ale{" "}
          <span>SmartWeb</span> nie ponosi odpowiedzialności za to, że aplikacja
          nie będzie działać w pełni funkcjonalnie, jeśli nie masz dostępu do
          Wi-Fi i nie pozostało Ci nic z limitu danych.
        </p>
        <p>
          Jeśli korzystasz z aplikacji poza obszarem, w którym działa sieć
          Wi-Fi, musisz pamiętać, że nadal obowiązują warunki umowy z operatorem
          sieci komórkowej. W rezultacie możesz zostać obciążony przez swojego
          dostawcę sieci komórkowej kosztami transmisji danych za czas
          połączenia w trakcie korzystania z aplikacji lub innymi opłatami
          naliczanymi przez osoby trzecie. Korzystając z aplikacji, użytkownik
          przyjmuje na siebie odpowiedzialność za wszelkie takie opłaty, w tym
          opłaty za dane roamingowe, jeśli korzysta z aplikacji poza terytorium
          swojego kraju (tj. regionu lub kraju) bez wyłączenia roamingu danych.
          Jeśli nie jesteś płatnikiem rachunku za urządzenie, na którym
          korzystasz z aplikacji, pamiętaj, że zakładamy, iż otrzymałeś zgodę
          płatnika na korzystanie z aplikacji.
        </p>
        <p>
          Podobnie, <span>SmartWeb</span> nie zawsze może wziąć odpowiedzialność
          za sposób, w jaki korzystasz z aplikacji, np. musisz upewnić się, że
          Twoje urządzenie jest naładowane - jeśli wyczerpie się bateria i nie
          będziesz mógł go włączyć, aby skorzystać z Usługi,{" "}
          <span>SmartWeb</span> nie może wziąć za to odpowiedzialności.
        </p>
        <p>
          W odniesieniu do odpowiedzialności <span>SmartWeb</span> za
          korzystanie z aplikacji, podczas korzystania z aplikacji należy
          pamiętać, że chociaż dokładamy wszelkich starań, aby aplikacja była
          zawsze aktualna i poprawna, polegamy na osobach trzecich, które
          dostarczają nam informacje, abyśmy mogli je udostępnić.{" "}
          <span>SmartWeb</span> nie ponosi żadnej odpowiedzialności za
          jakiekolwiek straty, bezpośrednie lub pośrednie, które poniesiesz w
          wyniku polegania wyłącznie na tej funkcjonalności aplikacji.
        </p>
        <p>
          W pewnym momencie możemy zechcieć zaktualizować aplikację. Aplikacja
          jest obecnie dostępna dla systemu Android - wymagania dla tego systemu
          (i dla każdego dodatkowego systemu, na który zdecydujemy się
          rozszerzyć dostępność aplikacji) mogą się zmienić, a Ty będziesz
          musiał pobrać aktualizacje, jeśli chcesz nadal korzystać z aplikacji.
          <span>SmartWeb</span> nie obiecuje, że zawsze będzie aktualizować
          aplikację tak, aby była ona odpowiednia dla Ciebie i/lub działała z
          wersją Androida, którą masz zainstalowaną na swoim urządzeniu. Możemy
          również zaprzestać dostarczania aplikacji i możemy zakończyć jej
          używanie w dowolnym momencie bez konieczności powiadamiania
          użytkownika o tym fakcie. Jeśli nie poinformujemy Cię inaczej, po
          rozwiązaniu umowy (a) wygasną prawa i licencje przyznane Ci w
          niniejszych warunkach; (b) musisz zaprzestać korzystania z aplikacji i
          (w razie potrzeby) usunąć ją ze swojego urządzenia.
        </p>
        <h2>Zmiany w Regulaminie</h2>
        <p>
          Od czasu do czasu możemy aktualizować nasz Regulamin. Dlatego zaleca
          się okresowe przeglądanie tej strony w celu zapoznania się z wszelkimi
          zmianami. Powiadomimy Cię o wszelkich zmianach, publikując nowe
          Warunki na tej stronie. Niniejsze warunki obowiązują od dnia
          2022-03-24.
        </p>
        <h2>Kontakt z nami</h2>
        <p>
          Jeśli masz jakiekolwiek pytania lub sugestie dotyczące naszych
          Warunków, skontaktuj się z nami pod adresem{" "}
          <a href="mailto:contact@beautifulplaces.top">
            contact@beautifulplaces.top
          </a>
        </p>
      </div>
      <Footer />
    </Page>
  );
};

export default TermsAndConditions;
